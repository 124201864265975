import React from "react"
import LocalizedLink from "../localizedLink"
import useTranslations from "../useTranslations"

const CompanyLeftNav = () => {
    const { companyInfo, companyProfile, history, office, isoCertificate, jcssCertificate, privacy  } = useTranslations()

    const ActiveStyle = {
        backgroundColor: '#63B1BC',
        color: '#fff'
    }

    const pathName = typeof window !== 'undefined' ? window.location.pathname : '';

    return (
      <>
        <div className="leftnav">
          <ul className="leftnav-links pc">
            <li className="leftnav-link">
              <LocalizedLink activeStyle={ActiveStyle} to={`/company/`}>
                {companyProfile}
              </LocalizedLink>
            </li>
            {
              (pathName.indexOf('/en') !== -1) ?
                  null:
                  <li className="leftnav-link">
                    <LocalizedLink activeStyle={ActiveStyle} to={`/company/history/`}>
                      {history}
                    </LocalizedLink>
                  </li>
            }
            <li className="leftnav-link">
              <LocalizedLink activeStyle={ActiveStyle} to={`/company/office/`}>
                {office}
              </LocalizedLink>
            </li>
            <li className="leftnav-link">
              <LocalizedLink activeStyle={ActiveStyle} to={`/company/iso/`}>
                {isoCertificate}
              </LocalizedLink>
            </li>
            <li className="leftnav-link">
              <LocalizedLink activeStyle={ActiveStyle} to={`/company/jcss/`}>
                {jcssCertificate}
              </LocalizedLink>
            </li>
            <li className="leftnav-link">
              <LocalizedLink activeStyle={ActiveStyle} to={`/company/privacy/`}>
                {privacy}
              </LocalizedLink>
            </li>
          </ul>

          <div className={`ac sp`}>
            <input
              className="ac-input"
              id="company-info"
              name="company-info"
              type="checkbox"
            />
            <label className="ac-label" htmlFor="company-info">
              {companyInfo}
            </label>
            <section className={` ac-text ac-text-footer `}>
              <ul className="leftnav-links">
                <li className="leftnav-link">
                  <LocalizedLink to={`/company/`}>
                    {companyProfile}
                  </LocalizedLink>
                </li>
                {
                  (pathName.indexOf('/en') !== -1) ?
                      null:
                      <li className="leftnav-link">
                        <LocalizedLink to={`/company/history/`}>
                          {history}
                        </LocalizedLink>
                      </li>
                }
                <li className="leftnav-link">
                  <LocalizedLink to={`/company/office/`}>{office}</LocalizedLink>
                </li>
                <li className="leftnav-link">
                  <LocalizedLink to={`/company/iso/`}>
                    {isoCertificate}
                  </LocalizedLink>
                </li>
                <li className="leftnav-link">
                  <LocalizedLink to={`/company/jcss/`}>
                    {jcssCertificate}
                  </LocalizedLink>
                </li>
                <li className="leftnav-link">
                  <LocalizedLink to={`/company/privacy/`}>
                    {privacy}
                  </LocalizedLink>
                </li>
              </ul>
            </section>
          </div>
        </div>
      </>
    );
}

export default CompanyLeftNav

